import { LayoutProps } from "@pankod/refine-core";
import { useMenu, useNavigation, useRouterContext, useRefineContext } from "@pankod/refine-core";

export const Layout: React.FC<LayoutProps> = ({ children }) => {
    const { menuItems, selectedKey } = useMenu();
    const { hasDashboard } = useRefineContext();
    const { Link } = useRouterContext();
    // You can also use navigation helpers from `useNavigation` hook instead of `Link` from your Router Provider.
    // const { push } = useNavigation();

    return (
        <div className="flex min-h-screen flex-col">
            <div className="mb-2 border-b py-2">
                <div className="container mx-auto">
                    {/*<div className="flex items-center gap-2">*/}
                    {/*    /!*<Link to="/">*!/*/}
                    {/*    /!*    <img*!/*/}
                    {/*    /!*        className="w-12"*!/*/}
                    {/*    /!*        src="../refine.svg"*!/*/}
                    {/*    /!*        alt="Logo"*!/*/}
                    {/*    /!*        width="120"*!/*/}
                    {/*    /!*        height="50"*!/*/}
                    {/*    /!*    />*!/*/}
                    {/*    /!*</Link>*!/*/}
                    {/*    <ul>*/}
                    {/*        {hasDashboard && (*/}
                    {/*            <li>*/}
                    {/*                <Link to="/">*/}
                    {/*                    <a style={{ fontWeight: selectedKey === "/" ? "bold" : "normal" }}>*/}
                    {/*                        <span>Dashboard</span>*/}
                    {/*                    </a>*/}
                    {/*                </Link>*/}
                    {/*            </li>*/}
                    {/*        )}*/}
                    {/*        {menuItems.map(({ name, route }) => {*/}
                    {/*            const isSelected = route === selectedKey;*/}
                    {/*            return (*/}
                    {/*                <li key={name}>*/}
                    {/*                    <Link to={route}>*/}
                    {/*                        /!*<a style={{ fontWeight: isSelected ? "bold" : "normal" }}>*!/*/}
                    {/*                        /!*    {icon}*!/*/}
                    {/*                        /!*    <span>{label ?? name}</span>*!/*/}
                    {/*                        /!*</a>*!/*/}
                    {/*                    </Link>*/}
                    {/*                </li>*/}
                    {/*            );*/}
                    {/*        })}*/}
                    {/*    </ul>*/}
                    {/*</div>*/}
                </div>
            </div>
            <div className="bg-white">{children}</div>
        </div>
    );
};