import * as React from "react";
import {
    IResourceComponentsProps,
    useMany,
    useDeleteMany,
} from "@pankod/refine-core";

import {
    List,
    Table,
    TextField,
    useTable,
    Space,
    EditButton,
    ShowButton,
    Button, useSelect,
} from "@pankod/refine-antd";

import { IVideos, ICategory } from "interfaces";

export const PostList: React.FC<IResourceComponentsProps> = () => {
    const { tableProps } = useTable<IVideos>();

    const { selectProps } = useSelect<ICategory>({
        resource: "videos",
    });
    const videoIds =
        tableProps?.dataSource?.map((item) => item.id) ?? [];
    const { data, isLoading } = useMany<IVideos>({
        resource: "videos",
        ids: videoIds,
        queryOptions: {
            enabled: videoIds.length > 0,
        },
    });

    const [selectedRowKeys, setSelectedRowKeys] = React.useState<React.Key[]>(
        [],
    );

    const { mutate, isLoading: deleteManyIsLoading } = useDeleteMany<IVideos>();

    const deleteSelectedItems = () => {
        mutate(
            {
                resource: "videos",
                ids: selectedRowKeys.map(String),
            },
            {
                onSuccess: () => {
                    setSelectedRowKeys([]);
                },
            },
        );
    };

    const onSelectChange = (selectedRowKeys: React.Key[]) => {
        setSelectedRowKeys(selectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
        selections: [
            Table.SELECTION_ALL,
            Table.SELECTION_INVERT,
            Table.SELECTION_NONE,
        ],
    };

    const hasSelected = selectedRowKeys.length > 0;

    return (
        <List
            pageHeaderProps={{
                subTitle: (
                    <>
                        <Button
                            type="primary"
                            onClick={deleteSelectedItems}
                            disabled={!hasSelected}
                            loading={deleteManyIsLoading}
                        >
                            Delete Selected
                        </Button>
                        <span style={{ marginLeft: 8 }}>
                            {hasSelected
                                ? `Selected ${selectedRowKeys.length} items`
                                : ""}
                        </span>
                    </>
                ),
            }}
        >
            <Table {...tableProps} rowSelection={rowSelection} rowKey="id">
                <Table.Column dataIndex="id" title="ID" />
                <Table.Column dataIndex="title" title="Title" />
                <Table.Column dataIndex="created_at" title="Created" />
                <Table.Column
                    key="hasPlayed"
                    dataIndex="hasPlayed"
                    title="Played"
                    sorter
                />
                {/*<Table.Column*/}
                {/*    dataIndex="has_played"*/}
                {/*    title="Played"*/}
                {/*    render={(value) => {*/}
                {/*        if (isLoading) {*/}
                {/*            return <TextField value="Loading..." />;*/}
                {/*        }*/}
                {/*        console.log('value: ', value)*/}
                {/*        console.log('data: ', data)*/}
                {/*        return (*/}
                {/*            <TextField*/}
                {/*                value={*/}
                {/*                    data?.data.find((item) => item.hasPlayed === value)*/}
                {/*                        ?.hasPlayed.toString()*/}
                {/*                }*/}
                {/*            />*/}
                {/*        );*/}
                {/*    }}*/}
                {/*/>*/}
                <Table.Column
                    key="videoId"
                    dataIndex="videoId"
                    title="VideoId"
                    sorter
                />

                <Table.Column<IVideos>
                    title="Actions"
                    dataIndex="actions"
                    render={(_, record) => (
                        <Space>
                            <EditButton
                                hideText
                                size="small"
                                recordItemId={record.id}
                            />
                            <ShowButton
                                hideText
                                size="small"
                                recordItemId={record.id}
                            />
                        </Space>
                    )}
                />
            </Table>
        </List>
    );
};