import React, { useState } from "react";
import { IResourceComponentsProps } from "@pankod/refine-core";

import {
    Alert,
    Button,
    Edit,
    Form,
    Input,
    ListButton,
    RcFile,
    RefreshButton,
    Select,
    Upload,
    useForm,
    useSelect,
} from "@pankod/refine-antd";

import ReactMarkdown from "react-markdown";
import ReactMde from "react-mde";

import "react-mde/lib/styles/css/react-mde-all.css";

import { IVideos, ICategory } from "interfaces";
import { supabaseClient, normalizeFile } from "utility";

export const PostEdit: React.FC<IResourceComponentsProps> = () => {
    const [isDeprecated, setIsDeprecated] = useState(false);
    const { formProps, saveButtonProps, queryResult } = useForm<IVideos>({
        liveMode: "manual",
        onLiveEvent: () => {
            setIsDeprecated(true);
        },
    });

    const postData = queryResult?.data?.data;
    // const { selectProps: categorySelectProps } = useSelect<ICategory>({
    //     resource: "categories",
    //     defaultValue: postData?.categoryId,
    // });

    const [selectedTab, setSelectedTab] = useState<"write" | "preview">(
        "write",
    );

    const handleRefresh = () => {
        queryResult?.refetch();
        setIsDeprecated(false);
    };

    return (
        <Edit
            saveButtonProps={saveButtonProps}
            pageHeaderProps={{
                extra: (
                    <>
                        <ListButton />
                        <RefreshButton onClick={handleRefresh} />
                    </>
                ),
            }}
        >
            {isDeprecated && (
                <Alert
                    message="This post is changed. Reload to see it's latest version."
                    type="warning"
                    style={{
                        marginBottom: 20,
                    }}
                    action={
                        <Button
                            onClick={handleRefresh}
                            size="small"
                            type="ghost"
                        >
                            Refresh
                        </Button>
                    }
                />
            )}

            <Form {...formProps} layout="vertical">
                <Form.Item
                    label="Title"
                    name="title"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="VideoId"
                    name="videoId"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                {/*<Form.Item*/}
                {/*    label="Category"*/}
                {/*    name="categoryId"*/}
                {/*    rules={[*/}
                {/*        {*/}
                {/*            required: true,*/}
                {/*        },*/}
                {/*    ]}*/}
                {/*>*/}
                {/*    /!*<Select {...categorySelectProps} />*!/*/}
                {/*</Form.Item>*/}
                {/*<Form.Item*/}
                {/*    label="Content"*/}
                {/*    name="content"*/}
                {/*    rules={[*/}
                {/*        {*/}
                {/*            required: true,*/}
                {/*        },*/}
                {/*    ]}*/}
                {/*>*/}
                {/*    <ReactMde*/}
                {/*        selectedTab={selectedTab}*/}
                {/*        onTabChange={setSelectedTab}*/}
                {/*        generateMarkdownPreview={(markdown) =>*/}
                {/*            Promise.resolve(*/}
                {/*                <ReactMarkdown>{markdown}</ReactMarkdown>,*/}
                {/*            )*/}
                {/*        }*/}
                {/*    />*/}
                {/*</Form.Item>*/}
                {/*<Form.Item label="Images">*/}
                {/*    <Form.Item*/}
                {/*        name="images"*/}
                {/*        valuePropName="fileList"*/}
                {/*        normalize={normalizeFile}*/}
                {/*        noStyle*/}
                {/*    >*/}
                {/*        <Upload.Dragger*/}
                {/*            name="file"*/}
                {/*            listType="picture"*/}
                {/*            multiple*/}
                {/*            customRequest={async ({*/}
                {/*                file,*/}
                {/*                onError,*/}
                {/*                onSuccess,*/}
                {/*            }) => {*/}
                {/*                const rcFile = file as RcFile;*/}
                {/*                const fileUrl = `public/${rcFile.name}`;*/}

                {/*                const { error } = await supabaseClient.storage*/}
                {/*                    .from("refine")*/}
                {/*                    .upload(fileUrl, file, {*/}
                {/*                        cacheControl: "3600",*/}
                {/*                        upsert: true,*/}
                {/*                    });*/}

                {/*                if (error) {*/}
                {/*                    return onError?.(error);*/}
                {/*                }*/}
                {/*                const { data, error: urlError } =*/}
                {/*                    await supabaseClient.storage*/}
                {/*                        .from("refine")*/}
                {/*                        .getPublicUrl(fileUrl);*/}

                {/*                if (urlError) {*/}
                {/*                    return onError?.(urlError);*/}
                {/*                }*/}

                {/*                onSuccess?.(*/}
                {/*                    { url: data?.publicURL },*/}
                {/*                    new XMLHttpRequest(),*/}
                {/*                );*/}
                {/*            }}*/}
                {/*        >*/}
                {/*            <p className="ant-upload-text">*/}
                {/*                Drag & drop a file in this area*/}
                {/*            </p>*/}
                {/*        </Upload.Dragger>*/}
                {/*    </Form.Item>*/}
                {/*</Form.Item>*/}
            </Form>
        </Edit>
    );
};
